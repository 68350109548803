import React, { useContext } from "react"
import { graphql } from "gatsby"
import LanguageSwitch from "../components/languageSwitch.js"
import ThemeSwitch from "../components/themeSwitch.js"
import SoundButton from "../components/soundToggle.js"
import Seo from "../components/seo.js"
import { Helmet } from "react-helmet"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import { GlobalContext } from "../components/soundToggle.js"

const Privacy_policy = ({ location }) => {
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })

  const openContact = () => {
    play()
    const contactModal =
      typeof window !== `undefined`
        ? document.querySelector(".contactModalOpen")
        : undefined
    contactModal.click()
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <Seo pathName={location.pathname} />
      <main className="bodyWrapper">
        <LanguageSwitch />
        <SoundButton />
        <ThemeSwitch />
        <section className="card wide" style={{ overflow: "visible" }}>
          <h2 style={{ textAlign: "center" }}>
            Privacy Policy for Personal portfolio of Lars Ejaas
          </h2>
          <hr />
          <h1 class="Display1">Privacy Policy</h1>
          <hr />
          <p>
            <b>Last updated:</b> March 25, 2021
          </p>
          <p>
            This Privacy Policy describes My policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p>
            I use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the Privacy Policy
            Generator.
          </p>
          <h2>Interpretation and Definitions</h2>
          <h3>Interpretation</h3>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h3>Definitions</h3>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <b>Account</b> means a unique account created for You to access
              our Service or parts of my Service.
            </li>
            <li>
              <b>Company</b> (referred to as either "the Company", "We", "Us" or
              "Our" in this Agreement) refers to Personal portfolio of Lars
              Ejaas.
            </li>
            <li>
              <b>Cookies</b> are small files that are placed on Your computer,
              mobile device or any other device by a website, containing the
              details of Your browsing history on that website among its many
              uses.
            </li>
            <li>
              <b>Country</b> refers to: Denmark
            </li>
            <li>
              <b>Device</b> means any device that can access the Service such as
              a computer, a cellphone or a digital tablet.
            </li>
            <li>
              <b>Personal Data</b> is any information that relates to an
              identified or identifiable individual.
            </li>

            <li>
              <b>Service </b>refers to the Website.
            </li>

            <li>
              <b>Service Provider</b> means any natural or legal person who
              processes the data on behalf of the Company. It refers to
              third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used.
            </li>

            <li>
              <b>Usage Data</b> refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </li>

            <li>
              <b>Website</b> refers to Personal portfolio of Lars Ejaas,
              accessible from https://larsejaas.com
            </li>

            <li>
              <b>You</b> means the individual accessing or using the Service, or
              the company, or other legal entity on behalf of which such
              individual is accessing or using the Service, as applicable.
            </li>
          </ul>
          <h2>Collecting and Using Your Personal Data</h2>
          <h3>Types of Data Collected</h3>
          <p>
            <b>Personal Data</b>
          </p>
          <p>
            While using my Service, I may ask You to provide Me with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>Usage Data</li>
          </ul>
          <h3>Usage Data</h3>
          <p>No usage Data is collected when using the Service.</p>
          <p>
            The CDN provider for this page may collect information that Your
            browser sends whenever You visit my Service or when You access the
            Service by or through a mobile device.
          </p>
          <h3>Tracking Technologies and Cookies</h3>
          <p>
            I use Cookies to collect and track information to improve your
            user-experience on the page. The technologies I use may include:
          </p>
          <ul>
            <li>
              Cookies or Browser Cookies. A cookie is a small file placed on
              Your Device. You can instruct Your browser to refuse all Cookies
              or to indicate when a Cookie is being sent. However, if You do not
              accept Cookies, You may not be able to use some parts of my
              Service. Unless you have adjusted Your browser setting so that it
              will refuse Cookies, my Service may use Cookies.
            </li>
          </ul>
          <p>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser.
          </p>
          <p>
            I use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <ul>
            <li>
              <b>Necessary / Essential Cookies</b>
            </li>
          </ul>
          <p>Type: Session Cookies</p>
          <p>Administered by: Us</p>
          <p>
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to store information for fast pageloads and use
            of this page when offline. Without these Cookies, the services that
            You have asked for cannot be provided, and We only use these Cookies
            to provide You with those services.
          </p>
          <ul>
            <li>
              <b>Functionality Cookies</b>
            </li>
          </ul>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Me</p>
          <p>
            Purpose: These Cookies allow me to remember choices You make when
            You use the Website, such as remembering your color theme. The
            purpose of these Cookies is to provide You with a more personal
            experience and to avoid You having to re-enter your preferences
            every time You use the Website.
          </p>
          <h2>Use of Your Personal Data</h2>
          <p>
            The Company collects no Personal Data unless you provide them to me
            directly. If you choose to use the contactform on this page you will
            provide an email-adress and I will store this email to reply to your
            request. Please contact me if you would like med to delete your
            contact information - I only store this data to be able to contact
            you.
          </p>
          <h3>Netlify</h3>
          <span>
            <p>
              I use Netlify to host the live version of my site. For more
              information on the privacy practices of Netlify, please visit:{" "}
            </p>
            <a
              href="https://www.netlify.com/privacy/"
              target="_blank"
              rel="noreferrer noopener"
            >
              https://www.netlify.com/privacy/
            </a>
          </span>
          <p>
            The Company does not retain Usage Data for internal analysis
            purposes and have no access to any such data. No usage Data is
            retained and no analytical service like Google Analytics or similar
            is implemented on my page.
          </p>
          <h3>Law enforcement and legal requirements</h3>
          <p>
            I may concent to disclose Your Personal Data in the good faith
            belief that such action is necessary to:
          </p>
          <ul>
            <li>Comply with any legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
          <h2>Security of Your Personal Data</h2>
          <p>
            The security of Your Personal Data is important to me, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure.
          </p>{" "}
          <p>
            While I strive to use commercially acceptable means to protect Your
            Personal Data, I cannot guarantee its absolute security.
          </p>
          <h2>Links to Other Websites</h2>
          <p>
            My Service may contain links to other websites that are not operated
            by Us. If You click on a third party link including any share link
            You will be directed to that third party's site. I strongly advise
            You to review the Privacy Policy of every site You visit.
          </p>
          <p>
            I have no control over and assume no responsibility for the content,
            privacy policies or practices of any third party sites or services.
          </p>
          <h2>Changes to this Privacy Policy</h2>
          <p>
            I may update My Privacy Policy from time to time. I will notify You
            of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h2>Contact Me</h2>
          <span>
            <p>
              If you have any questions about this Privacy Policy, You can
              contact me by using this contact form:{" "}
            </p>
            <button
              class="noHrefLink"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={openContact}
              tabindex="0"
            >
              contact me
            </button>
            <p>.</p>
          </span>
        </section>
      </main>
    </>
  )
}

export default Privacy_policy

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
